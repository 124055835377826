import { navigate, PageProps } from "gatsby"
import React from "react"
import Button from "../../components/Button"
import ActeNecesare, {
  ActNecesar,
} from "../../components/CereriNew/ActeNecesare"
import AgreeToTerms from "../../components/CereriNew/AgreeToTerms"
import FormeErrorLine from "../../components/CereriNew/FormErrorLine"
import NewFormFileInput from "../../components/CereriNew/FormFileInput"
import NewTabs, { I_NewTabItem } from "../../components/CereriNew/NewTabs"
import SelectOption from "../../components/CereriNew/SelectOption"
import { casnicSvg, comercialSvg } from "../../components/Dash/svgs"
import Footer from "../../components/Footer"
import FormInput from "../../components/FormInput"
import { useFileInput } from "../../components/Hooks/useFileInput"
import useScrollRestorationToTop from "../../components/Hooks/useScrollRestorationToTop"
import { InfoMsgType } from "../../components/InfoMsg"
import SiteMenu from "../../components/siteMenu"
import { postCerereRacordare } from "../../utils/api/cerereRacordare"
import {
  ALFA_NUMERIC_ERROR,
  ALFA_NUMERIC_REGEX,
  CNP_ERROR,
  CNP_REGEX,
  EMAIL_ERROR,
  EMAIL_REGEX,
  PHONENUMBER_ERROR,
  PHONENUMBER_REGEX,
} from "../../utils/regex"
import { getUserInfo } from "../../utils/services/keepAuth"

import { Helmet } from "react-helmet"
import { calcFilesSize } from "../../components/Cereri/MultiFileUploader"
import PopupLayout from "../../components/Popups/PopupLayout"

type DataCerereRacordare = {
  isCasnic: boolean
  denumire: string
  cuicnp: string
  adresa: string
  localitate: string
  judet: string
  numeReprezentant?: string
  email: string
  telefon: string
  locConsum: {
    isSame: boolean
    adresa: string
    localitate: string
    judet: string
  }
  reprezentantLegal: {
    isReprezentant: boolean
    nume: string
  }
  modalitateRaspuns: number
  agreedToTerms: boolean
}
const initialFormData: DataCerereRacordare = {
  isCasnic: true,
  denumire: "",
  cuicnp: "",
  adresa: "",
  localitate: "",
  judet: "",
  email: "",
  telefon: "",
  locConsum: {
    isSame: true,
    adresa: "",
    localitate: "",
    judet: "",
  },
  reprezentantLegal: {
    isReprezentant: false,
    nume: "",
  },
  modalitateRaspuns: 1,
  agreedToTerms: false,
}
const listaActeNecesare: ActNecesar[][] = [
  [
    {
      title:
        "Act de proprietate a locuinței (cumpărare/închiriere/comodat/moștenire)",
    },
    {
      title:
        "Autorizație de construire (daca imobilul nu este menționat în act. proprietate)",
    },
    { title: "Buletinul persoanei care deține spațiul de drept" },
    { title: "Extras de carte funciară eliberat de O.C.P.I." },
    { title: "Schița imobilului unde vor funcționa aparatele de utilizare" },
    { title: "Planul de situație (scara 1:500 sau 1:1000) " },
  ],
  [
    { title: "Certificatul de înregistrare" },
    {
      title:
        "Documentele care atestă dreptul de proprietate/folosință, respectiv:",
      subItems: [
        "Autorizația de construire sau",
        "Actul de vânzare-cumpărare/contractul de închiriere/donație/etc.",
      ],
    },
    { title: "Extras de carte funciară eliberat de O.C.P.I." },
    { title: "Schița imobilului unde vor funcționa aparatele de utilizare" },
    { title: "Planul de situație (scara 1:500 sau 1:1000) " },
  ],
]
const cereriButtonText = ["Pasul următor", "Pasul următor", "Trimite cererea"]
const tipClientTabItems: I_NewTabItem[] = [
  {
    id: 1,
    title: "Casnic",
    icon: casnicSvg,
    iconClassName: "casnic",
  },
  {
    id: 2,
    title: "Comercial",
    icon: comercialSvg,
    iconClassName: "comercial",
  },
]
const locConsumTabItems: I_NewTabItem[] = [
  {
    id: 1,
    title: "Da",
  },
  {
    id: 2,
    title: "Nu",
  },
]
const reprezentatLegalTabItems: I_NewTabItem[] = [
  {
    id: 1,
    title: "Da",
  },
  {
    id: 2,
    title: "Nu",
  },
]
const agreeToTermsTabItems: I_NewTabItem[] = [
  {
    id: 1,
    title: "Nu sunt de acord",
  },
  {
    id: 2,
    title: "Sunt de acord",
  },
]
const raspunsOptions: I_NewTabItem[] = [
  {
    id: 1,
    title: "Telefonic",
  },
  {
    id: 2,
    title: "Ridicare personală",
  },
  {
    id: 3,
    title: "Email",
  },
]

function getFormErrorMessageForStep(step: number) {
  switch (step) {
    case 1:
      return "Te rugăm să anexezi documentele necesare"
    case 2:
      return "Te rugăm să completezi toate câmpurile"
    case 3:
      return "Acceptă termenii și condițiile pentru a putea trimite cererea"
    default:
      return ""
  }
}

function isButtonEnabled(
  step: number,
  formData: DataCerereRacordare,
  files: File[]
) {
  if (step === 1) {
    return files.length > 0 && calcFilesSize(files) < 14.99
  }
  if (step === 2) {
    return (
      ALFA_NUMERIC_REGEX(formData.denumire) &&
      (formData.isCasnic
        ? CNP_REGEX(formData.cuicnp)
        : ALFA_NUMERIC_REGEX(formData.cuicnp)) &&
      ALFA_NUMERIC_REGEX(formData.adresa) &&
      ALFA_NUMERIC_REGEX(formData.localitate) &&
      ALFA_NUMERIC_REGEX(formData.judet) &&
      EMAIL_REGEX(formData.email) &&
      PHONENUMBER_REGEX(formData.telefon) &&
      (!formData.locConsum.isSame
        ? ALFA_NUMERIC_REGEX(formData.locConsum.adresa) &&
          ALFA_NUMERIC_REGEX(formData.locConsum.localitate) &&
          ALFA_NUMERIC_REGEX(formData.locConsum.judet)
        : true) &&
      (formData.reprezentantLegal.isReprezentant
        ? ALFA_NUMERIC_REGEX(formData.reprezentantLegal.nume)
        : true)
    )
  }
  if (step === 3) {
    return formData.agreedToTerms
  }
  return false
}

export default function CerereRacordare({ location, params }: PageProps) {
  const [infoMsg, setInfoMsg] = React.useState<InfoMsgType>({
    isLoading: false,
    // status: "error",
  })
  const [step, setStep] = React.useState<number>(1)
  const [formData, setFormData] =
    React.useState<DataCerereRacordare>(initialFormData)
  const [nrHover, setNrHover] = React.useState(0)

  const timeOutRefs = React.useRef<NodeJS.Timeout[]>([])

  const fileInputRef = React.useRef<HTMLInputElement | null>(null)
  const { files, removeFile, resetFiles, changeFiles, uploadFiles } =
    useFileInput(fileInputRef)

  React.useEffect(() => {
    // Page unmount cleanup function
    return () => {
      timeOutRefs.current.forEach(clearTimeout)
    }
  }, [])

  React.useEffect(() => {
    const step = parseInt(params["step"] ?? "-1")
    if (!isNaN(step) && step > 0 && step < 5) {
      let maxStep = 1
      for (let i = maxStep; i < step; i++) {
        if (isButtonEnabled(i, formData, files)) {
          maxStep = i
        }
      }
      // console.log("Max step: ", maxStep, step)
      if (maxStep + 1 < step) {
        navigate(`../${maxStep + 1}`)
      } else setStep(Math.min(maxStep + 1, step))
      // navigate(`../${maxStep + 1}`)
    } else navigate("../1")
  }, [params])

  React.useEffect(() => {
    if (step === 4) {
      if (infoMsg.isLoading) {
      }
    }

    setNrHover(0)
  }, [step])

  useScrollRestorationToTop("body", location.pathname)

  const handleFormDataChange = (value: string, name: string) => {
    setFormData(oldFormData => ({
      ...oldFormData,
      [name]: value,
    }))
  }

  const handleReprezentatDataChange = (value: string, name: string) => {
    setFormData(oldFormData => ({
      ...oldFormData,
      reprezentantLegal: {
        ...oldFormData.reprezentantLegal,
        nume: value,
      },
    }))
  }

  const handleSubmit = async () => {
    const root = document.getElementById("cerereRacordarePage")
    if (root != null)
      root.scrollTo({
        top: 0,
        left: 0,
        behavior: "instant",
      })
    setInfoMsg({
      isLoading: true,
    })

    // console.log("Handle form submit")
    try {
      const data = new FormData()

      for (var i in files) data.append("file", files[i])

      if (getUserInfo() != null) data.append("accountId", getUserInfo()!._id)

      data.append("isCasnic", formData.isCasnic + "")
      data.append("denumire", formData.denumire)
      data.append("cuicnp", formData.cuicnp)
      data.append("adresa", formData.adresa)
      data.append("localitate", formData.localitate)
      data.append("judet", formData.judet)
      data.append("email", formData.email)
      data.append("telefon", formData.telefon)
      if (!formData.locConsum.isSame) {
        data.append("lcadresa", formData.locConsum.adresa)
        data.append("lclocalitate", formData.locConsum.localitate)
        data.append("lcjudet", formData.locConsum.judet)
      }
      if (formData.reprezentantLegal.isReprezentant) {
        data.append("numeReprezentantLegal", formData.reprezentantLegal.nume)
      }
      if (formData.numeReprezentant != null)
        data.append("numeReprezentantLegal", formData.numeReprezentant)
      data.append("modalitateRaspuns", formData.modalitateRaspuns + "")
      data.append("acordDate", formData.agreedToTerms + "")
      const apiResponse = await postCerereRacordare(data)
      if (apiResponse.type) {
        setInfoMsg({
          isLoading: true,
          status: "ok",
          msg: "Veți primi un email de confirmarea.  Te redirecționăm către pagina principală",
        })
        timeOutRefs.current.push(
          setTimeout(() => {
            navigate("/")
          }, 5000)
        )
        // setFormData(initialFormData)
      } else if (apiResponse.filesToLarge) {
        setInfoMsg({
          isLoading: true,
          status: "error",
          msg: "A apărut o eroare la transmiterea cererii. Fișierele încărcate depășesc limita de 20MB. Scoateți fișierele care au o dimensiunea mare. Vă vom solicita restul documentelor prin email. Te redirecționăm către pasul încarcare documente",
        })
        timeOutRefs.current.push(
          setTimeout(() => {
            setStep(2)
            setInfoMsg({ isLoading: false })
          }, 5000)
        )
      } else {
        setInfoMsg({
          isLoading: true,
          status: "error",
          msg: "Vă rugăm reîncercați. Acest pop-up va dispărea în câteva momente!",
        })
        timeOutRefs.current.push(
          setTimeout(() => {
            setInfoMsg({ isLoading: false })
          }, 3000)
        )
      }
    } catch (error) {
      console.warn(error)
      setInfoMsg({
        isLoading: true,
        status: "error",
        msg: "Vă rugăm reîncercați.Acest pop-up va dispărea în câteva momente!",
      })
      timeOutRefs.current.push(
        setTimeout(() => {
          setInfoMsg({ isLoading: false })
        }, 3000)
      )
    }
  }

  const handleLocConsumDataChange = (value: string, name: string) => {
    setFormData(oldData => ({
      ...oldData,
      locConsum: {
        ...oldData.locConsum,
        [name as keyof DataCerereRacordare["locConsum"]]: value,
      },
    }))
  }

  // console.log(formData)
  // console.log(files)

  console.log(infoMsg)

  return (
    <div id="cerereRacordarePage" className="cererePage">
      <Helmet>
        <title>Cerere racordare - Gazmir.ro</title>
        {/* <link rel="canonical" href="http://mysite.com/example" /> */}
        <meta
          name="description"
          content="Gazmir - furnizorul tau de gaze naturale. Depune o Cerere pentru racordarea la gaz. Vezi etapele si documentele necesare."
        />
        <meta
          name="keywords"
          content="Gazmir,Furnizor gaz,Furnizare gaz,Furnizare gaze naturale,gaze
              naturale,Cerere racordare Gazmir,Cerere racordare miroslava gazmir,Cerere racordare panciu gazmir,Cerere racordare odobesti gazmir,Cerere racordare chirnogi gazmir,Cerere racordare aroneanu"
        />
      </Helmet>
      <SiteMenu location={location} showPaymentRedirectLoading={false} />

      <form id="formRacordare" className="newCereriContainer">
        <h1 className="title">Cerere de racordare</h1>
        {step === 1 && (
          <div className="cerereStep1 cerereStep2">
            <NewTabs
              containerClassName="tipClientContainer"
              title="Tip client"
              subTitle="Ești client casnic sau comercial?"
              items={tipClientTabItems}
              idTabSelected={formData.isCasnic ? 1 : 2}
              setIdTabSelected={(id: number) =>
                setFormData(oldData => ({ ...oldData, isCasnic: id === 1 }))
              }
            />
            <div className="stepTitle marginTop24">Acte necesare</div>
            <div className="stepSubTitle">
              Te rugăm să te asiguri că toate datele sunt vizibile și lizibile
            </div>

            <ActeNecesare
              items={listaActeNecesare[formData.isCasnic ? 0 : 1]}
              withoutText
            />
            <NewFormFileInput
              files={files}
              changeFiles={changeFiles}
              removeFile={removeFile}
              fileInputRef={fileInputRef}
              id="fileInputracordare"
              buttonLabel="Anexează documente"
            />
          </div>
        )}
        {step === 2 && (
          <div className="cerereStep3">
            <div className="stepTitle">
              {formData.isCasnic ? "Date personale" : "Date firmă"}
            </div>
            <div className="formDate formDateIdentificare ncsShadow">
              <div className="formSectionTitle">
                {formData.isCasnic
                  ? "Date personale titular (din C.I.)"
                  : "Date de indetificare societate"}
              </div>
              <FormInput
                type="text"
                key={formData.isCasnic ? "CD" : "PD"}
                containerClassName="ncsInputContainer"
                label={
                  formData.isCasnic ? "Nume și prenume" : "Denumire societate"
                }
                name="denumire"
                onChange={handleFormDataChange}
                value={formData.denumire}
                errorTest={ALFA_NUMERIC_REGEX}
                errorMessage={ALFA_NUMERIC_ERROR}
                showWithoutTouched={nrHover > 1}
              />
              <FormInput
                type="text"
                key={formData.isCasnic ? "CC" : "PC"}
                containerClassName="ncsInputContainer"
                label={
                  formData.isCasnic
                    ? "CNP - Cod Numeric Personal"
                    : "Certificat unic de înregistrare (C.U.I.)"
                }
                name="cuicnp"
                onChange={handleFormDataChange}
                value={formData.cuicnp}
                errorTest={formData.isCasnic ? CNP_REGEX : ALFA_NUMERIC_REGEX}
                errorMessage={
                  formData.isCasnic ? CNP_ERROR : ALFA_NUMERIC_ERROR
                }
                showWithoutTouched={nrHover > 1}
              />

              <FormInput
                type="text"
                key={formData.isCasnic ? "CA" : "PA"}
                containerClassName="ncsInputContainer"
                // containerStyle={cereriStyles.inputContainer}
                // style={cereriStyles.input}
                // errorStyle={cereriStyles.inputError}
                label={
                  formData.isCasnic ? "Adresă Domiciliu" : "Adresă sediu social"
                }
                name="adresa"
                onChange={handleFormDataChange}
                value={formData.adresa}
                errorTest={ALFA_NUMERIC_REGEX}
                errorMessage={ALFA_NUMERIC_ERROR}
                showWithoutTouched={nrHover > 1}
              />
              <FormInput
                type="text"
                key={formData.isCasnic ? "CL" : "PL"}
                containerClassName="ncsInputContainer"
                // containerStyle={cereriStyles.inputContainer}
                // style={cereriStyles.input}
                // errorStyle={cereriStyles.inputError}
                label="Localitate"
                name="localitate"
                onChange={handleFormDataChange}
                value={formData.localitate}
                errorTest={ALFA_NUMERIC_REGEX}
                errorMessage={ALFA_NUMERIC_ERROR}
                showWithoutTouched={nrHover > 1}
              />
              <FormInput
                type="text"
                key={formData.isCasnic ? "CJ" : "PJ"}
                containerClassName="ncsInputContainer"
                // containerStyle={cereriStyles.inputContainer}
                // style={cereriStyles.input}
                // errorStyle={cereriStyles.inputError}
                label="Județ"
                name="judet"
                onChange={handleFormDataChange}
                value={formData.judet}
                errorTest={ALFA_NUMERIC_REGEX}
                errorMessage={ALFA_NUMERIC_ERROR}
                showWithoutTouched={nrHover > 1}
              />
              {!formData.isCasnic && (
                <FormInput
                  type="text"
                  key="PNR"
                  containerClassName="ncsInputContainer"
                  // containerStyle={cereriStyles.inputContainer}
                  // style={cereriStyles.input}
                  // errorStyle={cereriStyles.inputError}
                  label="Numele reprezentantului legal"
                  name="numeReprezentant"
                  onChange={handleFormDataChange}
                  value={formData.numeReprezentant}
                  errorTest={ALFA_NUMERIC_REGEX}
                  errorMessage={ALFA_NUMERIC_ERROR}
                  showWithoutTouched={nrHover > 1}
                />
              )}
              <FormInput
                type="text"
                key={formData.isCasnic ? "CE" : "PE"}
                containerClassName="ncsInputContainer"
                // containerStyle={cereriStyles.inputContainer}
                // style={cereriStyles.input}
                // errorStyle={cereriStyles.inputError}
                label="Email"
                name="email"
                onChange={handleFormDataChange}
                value={formData.email}
                errorTest={EMAIL_REGEX}
                errorMessage={EMAIL_ERROR}
                showWithoutTouched={nrHover > 1}
              />
              <FormInput
                type="text"
                key={formData.isCasnic ? "CT" : "PT"}
                containerClassName="ncsInputContainer"
                // containerStyle={cereriStyles.inputContainer}
                // style={cereriStyles.input}
                // errorStyle={cereriStyles.inputError}
                label="Număr de telefon"
                name="telefon"
                onChange={handleFormDataChange}
                value={formData.telefon}
                errorTest={PHONENUMBER_REGEX}
                errorMessage={PHONENUMBER_ERROR}
                showWithoutTouched={nrHover > 1}
              />
            </div>
            <NewTabs
              title="Loc consum"
              subTitle={`Locul de consum este același cu ${
                formData.isCasnic ? "domiciliul" : "sediul social al firmei"
              }?`}
              items={locConsumTabItems}
              idTabSelected={formData.locConsum.isSame ? 1 : 2}
              setIdTabSelected={(id: number) =>
                setFormData(oldData => ({
                  ...oldData,
                  locConsum: { ...oldData.locConsum, isSame: id === 1 },
                }))
              }
            />
            {!formData.locConsum.isSame && (
              <div className="formDate formDateLocConsum">
                <FormInput
                  type="text"
                  key="LCA"
                  containerClassName="ncsInputContainer"
                  label="Adresă loc consum"
                  name="adresa"
                  onChange={handleLocConsumDataChange}
                  value={formData.locConsum.adresa}
                  errorTest={ALFA_NUMERIC_REGEX}
                  errorMessage={ALFA_NUMERIC_ERROR}
                  showWithoutTouched={nrHover > 1}
                />
                <FormInput
                  type="text"
                  key="LCL"
                  containerClassName="ncsInputContainer"
                  // containerStyle={cereriStyles.inputContainer}
                  // style={cereriStyles.input}
                  // errorStyle={cereriStyles.inputError}
                  label="Localitate"
                  name="localitate"
                  onChange={handleLocConsumDataChange}
                  value={formData.locConsum.localitate}
                  errorTest={ALFA_NUMERIC_REGEX}
                  errorMessage={ALFA_NUMERIC_ERROR}
                  showWithoutTouched={nrHover > 1}
                />
                <FormInput
                  type="text"
                  key="LCJ"
                  containerClassName="ncsInputContainer"
                  // containerStyle={cereriStyles.inputContainer}
                  // style={cereriStyles.input}
                  // errorStyle={cereriStyles.inputError}
                  label="Județ"
                  name="judet"
                  onChange={handleLocConsumDataChange}
                  value={formData.locConsum.judet}
                  errorTest={ALFA_NUMERIC_REGEX}
                  errorMessage={ALFA_NUMERIC_ERROR}
                  showWithoutTouched={nrHover > 1}
                />
              </div>
            )}
            {formData.isCasnic && (
              <NewTabs
                title="Sunteți reprezentant legal? (mandatar)"
                subTitle=""
                items={reprezentatLegalTabItems}
                idTabSelected={
                  formData.reprezentantLegal.isReprezentant ? 1 : 2
                }
                setIdTabSelected={(id: number) =>
                  setFormData(oldData => ({
                    ...oldData,
                    reprezentantLegal: {
                      ...oldData.reprezentantLegal,
                      isReprezentant: id === 1,
                    },
                  }))
                }
              />
            )}
            {formData.isCasnic && formData.reprezentantLegal.isReprezentant && (
              <div className="formDate formDateLocConsum">
                <FormInput
                  type="text"
                  key="RPN"
                  containerClassName="ncsInputContainer"
                  // containerStyle={cereriStyles.inputContainer}
                  // style={cereriStyles.input}
                  // errorStyle={cereriStyles.inputError}
                  label="Numele reprezentantului legal"
                  name="nume"
                  onChange={handleReprezentatDataChange}
                  value={formData.reprezentantLegal.nume}
                  errorTest={ALFA_NUMERIC_REGEX}
                  errorMessage={ALFA_NUMERIC_ERROR}
                  showWithoutTouched={nrHover > 1}
                />
              </div>
            )}
            <SelectOption
              title="Cum doriți să primiți răspunsul nostru?"
              options={raspunsOptions}
              idSelected={formData.modalitateRaspuns}
              setIdSelected={(id: number) => {
                setFormData(oldData => ({ ...oldData, modalitateRaspuns: id }))
              }}
            />
          </div>
        )}

        {step === 3 && (
          <div className="cerereStep4">
            <div key={0} className="stepTitle">
              Am luat la cunoștință
            </div>
            <p key={1} className="legalInfo">
              <b>Am luat la cunoştinţă</b> de măsurile necesare pentru
              asigurarea securităţii persoanelor şi integrităţii bunurilor, în
              sensul că punerea în funcţiune a instalaţiei de utilizare se va
              face numai dacă prezint facturile emise de executantul lucrărilor
              aferente instalaţiei de gaze naturale şi instalaţii termice,
              inclusiv centrale termice, în care să fie prevăzute distinct
              materialele folosite şi execuţia lucrărilor. <br />
              <br />
              În cazul în care se constată că cererea nu este completată și/sau
              corecta, OSD notifică solicitantul, în termen de 5 zile lucrătoare
              de la data înregistrării cereri prin metoda aleasa la pct. d. din
              cererea de racordare.
              <br />
              <br /> În cazul în care solicitantul nu completează sau corecteaza
              cererea în termen de 30 de zile, cererea se clasează fără
              restituirea tarifului de analiză.
              <br />
              <br /> În termen de 2 zile lucrătoare de la data emiterii ATR, OSD
              comunică solicitantului prin metoda aleasa, faptul că a fost emis
              ATR si ca solicitantul are termen de 5 zile pentru a ridica
              personal sau prin mandatar de la sediul OSD, iar in cazul
              neridicarii ATR, cererea de racordare se claseaza.
              <br />
              <br /> Solicitantul are posibilitatea de a alege orice operator
              economic autorizat ANRE pentru proiectarea și/sau execuția
              sistemelor de distribuție a G.N., respectiv orice verificator de
              proiecte atestat ANRE pentru sistemele de distribuție a G.N. din
              lista prevăzută pe pagina de internet a ANRE - www.anre.ro sau de
              pe pagina de internet a OSD.
              <br />
              <br /> În considerarea prevederilor art.326 din CODUL PENAL
              referitoare la falsul în declaraţii, declar că toate informaţiile
              și documentele ce însoţesc prezenta cerere sunt corecte si reale/
              de asemenea mă angajez să prezint OSD, la solicitarea acestuia,
              documentele în original.
              <br />
              <br /> Îmi exprim consimţământul expres și necondiţionat la
              preluarea de către Gazmir Iași SRL a datelor cu caracter personal,
              astfel cum le-am furnizat sau vor fi furnizate de mine sau
              persoane mandatate de mine.
              <br />
              <br /> − Pentru publicarea pe pagina de internet OSD a
              informaţiilor cu privire la datele de contact și adresa locului de
              consum, solicitantul:
              <br />
            </p>
            <AgreeToTerms containerClassName="legalInfo" key={2} />
            <NewTabs
              key={3}
              items={agreeToTermsTabItems}
              idTabSelected={formData.agreedToTerms ? 2 : 1}
              setIdTabSelected={(id: number) =>
                setFormData(oldData => ({
                  ...oldData,
                  agreedToTerms: id === 2,
                }))
              }
            />
          </div>
        )}

        <FormeErrorLine
          status="error"
          errorMessage={getFormErrorMessageForStep(step)}
          doesFormHaveErrors={!isButtonEnabled(step, formData, files)}
          nrHover={nrHover}
          setNrHover={setNrHover}
        />
        {infoMsg.status !== "ok" && (
          <Button
            isLoading={infoMsg.isLoading}
            as={step < 3 ? "Link" : "button"}
            to={step < 3 ? `../${step + 1}` : undefined}
            disabled={!isButtonEnabled(step, formData, files)}
            aspect="primary"
            className="ncsButton"
            label={cereriButtonText[step - 1]}
            onClick={step < 3 ? undefined : handleSubmit}
          />
        )}
      </form>
      <PopupLayout
        querySelectorForBlurredBackground="#formRacordare"
        querySelectorForStopScrolling="#cerereRacordarePage"
        titleExtraClassName="popupExtraTitle"
        popupState={{
          showPopup:
            step === 3 && (infoMsg.isLoading || infoMsg.status != null),
          type: "infoCerere",
          title:
            infoMsg.isLoading && infoMsg.status == null
              ? "Se trimite cererea"
              : infoMsg.status === "ok"
              ? "Cererea a fost trimisă cu succes"
              : "A apărut o eroare la transmiterea cererii",
          infoMsg: infoMsg,
        }}
      />
      <Footer />
    </div>
  )
}

const testFormData = {
  isCasnic: true,
  denumire: "Sârbu Alexandru",
  cuicnp: "1940527225898",
  nrInreg: "",
  adresa: "Str. Gându, nr.6",
  localitate: "Iași",
  judet: "Iași",
  email: "sarbu.alex27@gmail.com",
  telefon: "0753500775",
  locConsum: {
    isSame: true,
    // adresa: "Str. Clopotari, nr.28",
    // localitate: "Iași",
    // judet: "Iași",
  },
  reprezentantLegal: {
    isReprezentant: false,
    // nume: "Marcoci-Gînju Cosmin",
  },
  modalitateRaspuns: 1,
  agreedToTerms: false,
  nume: "g",
}

const testDataPJ = {
  isCasnic: false,
  denumire: "S.C. AZZA DDC S.R.L.",
  cuicnp: "34728271",
  nrInreg: "J22/1181/2015",
  adresa: "Str. Gandu, nr. 6",
  localitate: "Aron",
  judet: "Iasi",
  email: "sarbu.alex27@gmail.com",
  telefon: "0710100100",
  locConsum: {
    isSame: false,
    adresa: "Str. Clopotari",
    localitate: "Aroneanu",
    judet: "Iasi",
  },
  reprezentantLegal: {
    isReprezentant: false,
    nume: "",
  },
  modalitateRaspuns: 1,
  agreedToTerms: true,
  numeReprezentant: "Sarbu Alexandru",
}
