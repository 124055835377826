import React from "react"
import { I_NewTabItem } from "./NewTabs"

type PropTypes = {
  containerClassName?: string
  title: string
  subTitle?: string
  options: I_NewTabItem[]
  idSelected: number
  setIdSelected: (id: number) => void
}

export default function SelectOption({
  containerClassName = "",
  title,
  subTitle,
  options,
  idSelected,
  setIdSelected,
}: PropTypes) {
  return (
    <div className={"selectOptionContainer " + containerClassName}>
      <div className="text">
        <div className="title">{title}</div>
        {subTitle && <div className="subTitle">{subTitle}</div>}
      </div>
      <div className="optionsList">
        {options.map(item => (
          <div
            key={item.id}
            className={`option ${
              item.id === idSelected ? "ncsSelectedBorder" : "ncsShadow"
            }`}
            onClick={() => setIdSelected(item.id)}
          >
            {item.icon != null && (
              <div className={"icon " + item.iconClassName}>{item.icon}</div>
            )}
            <div className="name">{item.title}</div>
          </div>
        ))}
      </div>
    </div>
  )
}
