import React from "react"

type PropTypes = {
  containerClassName?: string
}
export default function AgreeToTerms({ containerClassName = "" }: PropTypes) {
  return (
    <span className={containerClassName}>
      Poți consulta aici{" "}
      <a target="_blank" href="/documente/Terms&Conditions.pdf">
        <span>termeni și condițiile de utilizare</span>
      </a>{" "}
      și{" "}
      <a target="_blank" href="/documente/PrivacyPolicy.pdf">
        <span>politica de confidențialitate</span>
      </a>
      .
    </span>
  )
}
