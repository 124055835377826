import { ApiResponse, apiBase, checkStatus } from "./utils"

const baseUrl = `${apiBase}/api/cerereRacordare`

interface PostCerereRacordareResponse extends ApiResponse<null> {
  filesToLarge: boolean
}

export function postCerereRacordare(
  data
): Promise<PostCerereRacordareResponse> {
  const loginData = {
    method: "post",
    headers: {
      // azza: getToken(),
      Accept: "application/json",
    },
    body: data,
  }
  return fetch(baseUrl, loginData).then(
    checkStatus
  ) as Promise<PostCerereRacordareResponse>
}
